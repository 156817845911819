import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/wedding_planner1.svg"

const WeddingPage = () => (
    <Layout>
        <SEO title="Recevez gratuitement notre wedding planner"/>
            <div className={"page-header home"}>
                        <div className={"row"}>
                            <div className={"col-5"}>
                                <div >
                                    <h1>Un wedding planner gratuit</h1>
                                        <h2>Demandez et recevez gratuitement notre outil de gestion</h2>

                                </div>
                                <div className={"get-started"}>
                                    <a href="https://infovivr.typeform.com/to/o8XGHsev" >demande</a>
                                </div>
                            </div>
                            <div className={"col-7"}>
                                    <img alt={"Dashboard"} src={featureImage}/>
                            </div>
                        </div>
            </div>

    </Layout>
)

export default WeddingPage
